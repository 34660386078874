import React, { useRef, useEffect, useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

// Data
import { SPIRAL_GASKETS } from 'components/Solution/GasketData';
import SEOMeta from 'SEO/MetaTags';
import { SEO_DATA } from 'SEO/SEO_DATA';

// Components
import SolutionHead from 'components/Solution/SolutionHead';
import Catalogue from 'components/Solution/Catalogue';
import SpiralItem from 'components/Solution/SpiralItem';
import SpiralModal from 'components/Solution/SpiralModal';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

gsap.registerPlugin(ScrollTrigger);

const SolutionSpiral = () => {
  const { t, i18n } = useTranslation();
  const FadeUpRef = useRef([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [selectItem, setSelectItem] = useState(null);
  const [isEng, setIsEng] = useState(false);

  const lang = {
    ko: false,
    en: true,
    cn: false
  };

  const location = useLocation();

  const scrollToSection = (id, offset = 50) => {
    const element = document.getElementById(id);

    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset; // 요소의 위치를 계산
      const offsetPosition = elementPosition - offset; // 50px 위로 이동

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth', // 부드럽게 스크롤
      });
    }
  };

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      scrollToSection(location.state.sectionId);
    }
  }, [location])

  useDidMountEffect(() => {
    setIsEng(lang[i18n.language]);
  }, [i18n.language]);

  useEffect(() => {
    setIsEng(lang[i18n.language]);

    FadeUpRef.current.forEach((el) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          opacity: 0,
          y: 100,
        },
        {
          duration: 1,
          autoAlpha: 1,
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: el,
            start: 'top bottom',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (selectItem) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [selectItem]);

  const handleModal = (listItem) => {
    setSelectItem(listItem);
  };

  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  const charList = [
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_ic01.svg',
      name: 'Application',
      desc1: t('solution.spiral.application.first'),
      desc2: t('solution.spiral.application.second'),
      desc3: t('solution.spiral.application.third')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_ic02.svg',
      name: 'Advantage',
      desc1: t('solution.spiral.advantage.first'),
      desc2: t('solution.spiral.advantage.second'),
      desc3: t('solution.spiral.advantage.third')
    },
  ];

  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  };

  return (
    <React.Fragment>
      <SEOMeta pageData={SEO_DATA.spiral}/>
      {/* 루트별 공통 비주얼 헤더 */}
      <SolutionHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* 솔루션 섹션 */}
        <section className='solution_sec'>
          <div className='sec_inner mo'>
            <div className='page_tit'>
              <h4>Spiral Gasket Series</h4>
              <p>{t('solution.spiral.title')}</p>
            </div>
            <div className='solution_con'>
              <div className='char_con'>
                <ul className={isEng ? 'eng spiral_char' : 'spiral_char'} ref={el => addToRefs(el, FadeUpRef)}>
                  {charList.map((item) => (
                    <li key={item.name}>
                      <div className='char_in'>
                        <div className='char_info'>
                          <div className='char_ic'>
                            <img src={item.img} alt={item.name}/>
                          </div>
                          <strong>{item.name}</strong>
                        </div>
                        <div className='char_desc'>
                          <p>{item.desc1}</p>
                          <p>{item.desc2}</p>
                          <p>{item.desc3}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='list_con'>

                <div id='type-normal' className='list-normal type-con'>
                  {/* Normal Type */}
                  <div className='type-gasket' ref={el => addToRefs(el, FadeUpRef)}>
                    <h6 className='type-tit'>{SPIRAL_GASKETS.normal.type}</h6>
                  </div>

                  {!isMobile &&
                    <ul className='spiral_list'>
                      {SPIRAL_GASKETS.normal.items.map((listItem, index) =>
                        <SpiralItem key={index} {...listItem} />
                      )}
                    </ul>
                  }

                  {isMobile &&
                    <div ref={el => addToRefs(el, FadeUpRef)}>
                      <Swiper className='spiral_list' spaceBetween={12} slidesPerView={1.14}>
                        {SPIRAL_GASKETS.normal.items.map((listItem) =>
                          <SwiperSlide
                            className='list_item'
                            key={listItem.name}
                            ref={el => addToRefs(el, FadeUpRef)}
                            onClick={() => handleModal(listItem)}
                          >
                            <div className='item_in'>
                              <div className='item_img'>
                                <img src={listItem.image} alt={listItem.name}/>
                              </div>
                              <div className='item_desc'>
                                <strong>{listItem.name}</strong>
                                <span>{formatData(listItem.desc)}</span>
                                <ul>
                                  {listItem.list.map((prop, propIndex) =>
                                    <li key={propIndex}>
                                      <strong className='cate'>{prop.label}</strong>
                                      <span className='info'>{formatData(prop.value)}</span>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </SwiperSlide>
                        )}
                      </Swiper>
                      <p className='m_info'>※ 제품 클릭 시 상세 팝업 확인이 가능합니다.</p>
                    </div>
                  }
                </div>
                <div id='type-form' className='list-form type-con'>
                  {/* Form Type */}
                  <div className='type-gasket' ref={el => addToRefs(el, FadeUpRef)}>
                    <h6 className='type-tit'>{SPIRAL_GASKETS.form.type}</h6>
                  </div>

                  {!isMobile &&
                    <ul className='spiral_list'>
                      {SPIRAL_GASKETS.form.items.map((listItem, index) =>
                        <SpiralItem key={index} {...listItem} />
                      )}
                    </ul>
                  }

                  {isMobile &&
                    <div ref={el => addToRefs(el, FadeUpRef)}>
                      <Swiper className='spiral_list' spaceBetween={12} slidesPerView={1.14}>
                        {SPIRAL_GASKETS.form.items.map((listItem) =>
                          <SwiperSlide
                            className='list_item'
                            key={listItem.name}
                            ref={el => addToRefs(el, FadeUpRef)}
                            onClick={() => handleModal(listItem)}
                          >
                            <div className='item_in'>
                              <div className='item_img'>
                                <img src={listItem.image} alt={listItem.name}/>
                              </div>
                              <div className='item_desc'>
                                <strong>{listItem.name}</strong>
                                <span>{formatData(listItem.desc)}</span>
                                <ul>
                                  {listItem.list.map((prop, propIndex) =>
                                    <li key={propIndex}>
                                      <strong className='cate'>{prop.label}</strong>
                                      <span className='info'>{formatData(prop.value)}</span>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </SwiperSlide>
                        )}
                      </Swiper>
                      <p className='m_info'>※ 제품 클릭 시 상세 팝업 확인이 가능합니다.</p>
                    </div>
                  }
                </div>
                <div className='catalogue_btn' ref={el => addToRefs(el, FadeUpRef)}>
                  <Catalogue/>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* 모달 팝업 */}
        {isMobile && selectItem && (
          <SpiralModal item={selectItem} onClose={() => setSelectItem(null)}/>
        )}
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  & .solution_sec {
    position: relative;
    padding: 140px 0 200px;

    & .solution_con {
      margin-top: 80px;

      .char_con {
        position: relative;

        & > .spiral_char {
          display: flex;
          gap: 0 40px;

          &.eng li .char_in .char_desc p {
            font-size: 16px;
          }

          li {
            flex: 1;

            .char_in {
              background: var(--color-grey-5);
              padding: 60px 40px;
              min-height: 560px;

              .char_info {
                text-align: center;

                .char_ic {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  width: 120px;
                  height: 120px;
                  background: var(--color-white);
                  border-radius: 50%;
                }

                strong {
                  margin-top: 30px;
                  display: block;
                  font-weight: 700;
                  font-size: var(--font-card-tit);
                  color: var(--color-grey-90);
                }
              }

              .char_desc {
                margin-top: 50px;
                text-align: center;

                p {
                  display: block;
                  font-weight: 400;
                  font-size: var(--font-page-s);
                  line-height: 1.2;
                  color: var(--color-grey-80);

                  + p {
                    margin-top: 30px;
                  }
                }
              }
            }
          }
        }
      }

      .list_con {
        position: relative;

        .type-con {
          margin-top: 90px;
        }

        .type-gasket {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: Center;
          width: 100%;
          height: 50px;
          background: var(--primary-color);
          border-radius: 12px;

          h6 {
            font-weight: 700;
            font-size: var(--font-nav-tit);
            color: var(--color-white);
          }
        }

        & .spiral_list {
          margin-top: 36px;
          position: relative;

          & .list_item {

            & .item_in {
              padding: 35px 30px;
              display: flex;
              align-items: center;
              gap: 0 30px;
              border: 1px solid var(--color-grey-20);

              & .item_img {
                max-width: 400px;
              }

              & .item_desc {
                flex: 1;

                & > strong {
                  font-weight: 700;
                  font-size: var(--font-card-tit);
                  color: var(--color-grey-100);
                }

                & > span {
                  margin-top: 10px;
                  display: block;
                  font-weight: 500;
                  font-size: var(--font-nav-sub-tit);
                  color: var(--color-grey-60);
                }

                & p {
                  margin-top: 30px;
                  display: block;
                  font-weight: 400;
                  font-size: var(--font-nav-sub-tit);
                  line-height: 1.5;
                  color: var(--color-grey-80);
                  white-space: pre-line;
                }

                & ul {
                  margin-top: 60px;
                  display: flex;
                  flex-wrap: wrap;
                  border-top: 1px solid var(--color-grey-60);
                  border-bottom: 1px solid var(--color-grey-60);

                  & li {
                    width: 50%;
                    text-align: center;

                    & .cate {
                      padding: 15px 0;
                      display: block;
                      font-weight: 600;
                      font-size: var(--font-nav-sub-tit);
                      color: var(--color-grey-60);
                      letter-spacing: 0.03rem;
                    }

                    & .info {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-top: 1px solid var(--color-grey-20);
                      min-height: 70px;
                      font-weight: 400;
                      font-size: var(--font-nav-sub-tit);
                      line-height: 1.2;
                      color: var(--color-grey-90);
                    }
                  }

                  & li:nth-child(n+3) {
                    border-top: 1px solid var(--color-grey-60);
                  }
                }
              }
            }

            & + li {
              margin-top: 30px;
            }
          }
        }

        & .catalogue_btn {
          margin-top: 45px;
          display: flex;
          justify-content: flex-end;

          & a {
            cursor: pointer;
            position: relative;
            padding: 0 30px;
            display: flex;
            align-items: center;
            height: 50px;
            line-height: 50px;
            border: 1px solid var(--primary-color);
            border-radius: 100px;
            font-weight: 500;
            font-size: var(--font-btn-18);
            color: var(--primary-color);
            font-family: var(--font-family);
            transition: var(--transition);

            &::after {
              content: '';
              display: block;
              width: 25px;
              height: 25px;
              background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/down_ic.svg') no-repeat;
              background-size: cover;
              transition: var(--transition);
            }

            &:hover {
              color: var(--color-white);
              background: var(--primary-color);

              &::after {
                filter: grayscale(1) brightness(5);
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    & .solution_sec {
      position: relative;
      padding: 70px 0 90px;

      & .solution_con {
        margin-top: 60px;

        .char_con {
          padding: 0 20px;

          & > .spiral_char {
            display: block;

            &.eng li .char_in .char_desc p {
              font-size: 16px;
            }

            li {
              flex: 1;

              .char_in {
                background: var(--color-grey-5);
                padding: 20px 15px;
                min-height: 310px;

                .char_info {
                  text-align: center;

                  .char_ic {
                  }

                  strong {
                    margin-top: 30px;
                  }
                }

                .char_desc {
                  margin-top: 50px;
                  text-align: center;

                  p {
                    display: block;

                    + p {
                      margin-top: 30px;
                    }
                  }
                }
              }
            }

            li + li {
              margin-top: 16px;
            }
          }
        }

        .list_con {
          padding: 0 0 0 20px;

          .type-con {
            margin-top: 60px;
            padding-right: 20px;

            .type-gasket {
              height: 35px;
              line-height: 35px;
            }
          }


          & .spiral_list {
            position: relative;

            & .list_item {

              & .item_in {
                padding: 12px;
                display: block;

                & .item_img {
                  max-width: none;
                  width: 100%;

                  img {
                    width: 100%;
                  }
                }

                & .item_desc {
                  margin-top: 20px;
                  width: 100%;

                  strong {
                    white-space: pre-line;
                  }

                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  & p {
                    margin-top: 15px;
                  }

                  & ul {
                    margin-top: 20px;
                    display: block;

                    & li {
                      width: 100%;
                      padding: 12px;
                      text-align: left;

                      + li {
                        border-top: 1px solid var(--color-grey-60);
                      }

                      & .cate {
                        border: none;
                        flex: 0.7;
                        padding: 0;
                      }

                      & .info {
                        margin-top: 5px;
                        border: none;
                        flex: 1;
                        display: block;
                        min-height: auto;
                        height: auto;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        br {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }

              & + li {
                margin-top: 30px;
              }
            }
          }

          .m_info {
            margin-top: 10px;
            display: block;
            font-weight: 700;
            font-size: var(--font-history-txt);
            color: var(--primary-color);
          }

          & .catalogue_btn {
            margin-top: 30px;
            padding: 0 16px;

            & a {
              padding: 0 16px;
              height: 40px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    & .solution_sec {
      position: relative;
      padding: 35px 0 45px;

      & .solution_con {
        margin-top: 40px;

        .char_con {
          & > .spiral_char {
            display: block;

            li {
              .char_in {
                .char_info {
                  strong {
                    margin-top: 15px;
                  }
                }

                .char_desc {
                  margin-top: 30px;
                  text-align: center;

                  p {
                    + p {
                      margin-top: 15px;
                    }
                  }
                }
              }
            }
          }
        }

        .list_con {
          .type-con {
            margin-top: 30px;
          }

          & .spiral_list {
            position: relative;

            & > li {
              & + li {
                margin-top: 15px;
              }
            }
          }
        }

        & .catalogue_btn {
          margin-top: 20px;
        }
      }
    }
  }
`;

export default SolutionSpiral;