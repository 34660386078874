import React, { useState, useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import CompanyHead from 'components/Company/CompanyHead';

gsap.registerPlugin(ScrollTrigger);

const CompanyCeo = () => {
  const { t } = useTranslation();
  const txtRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);

  useEffect(() => {
    const txtItem = txtRef.current;

    gsap.fromTo(
      txtItem,
      {
        autoAlpha: 0,
        x: 200,
        opacity: 0,
      },
      {
        duration: 1,
        autoAlpha: 1,
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: txtItem,
          start: 'top bottom-=250',
          toggleActions: 'restart none none reverse',
        },
      })

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  };

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <CompanyHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* CEO 인사 */}
        <section className='ceo_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{formatData(t('company.ceo.title'))}</h4>
            </div>
            <div className='ceo_box'>
              <div className='ceo_img'>
                <img src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/ceo_bg.webp' alt='대표이사'/>
              </div>
              <div className='ceo_desc'>
                <div className='desc_in' ref={txtRef}>
                  <p>{formatData(t('company.ceo.greeting.first'))}</p>
                  <p>{formatData(t('company.ceo.greeting.second'))}</p>
                  <p>{formatData(t('company.ceo.greeting.third'))}</p>
                  <p>{formatData(t('company.ceo.greeting.fourth'))}</p>
                </div>
                <span>{t('company.ceo.greeting.fifth')}</span>
              </div>
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  & .ceo_sec {
    position: relative;
    padding: 140px 0 180px;

    & .ceo_box {
      margin-top: 80px;
      display: flex;
      gap: 0 50px;

      & > div {
        width: 50%;

        &.ceo_img img {
          width: 100%;
        }

        &.ceo_desc {
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          & .desc_in {
            position: relative;
            width: 100%;

            & p {
              font-weight: 400;
              font-size: var(--font-page-s);
              line-height: 1.2;
              color: var(--color-grey-80);
              white-space: pre-line;

              & + p {
                margin-top: 30px;
              }
            }
          }

          span {
            position: relative;
            margin-top: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 0 10px;
            font-weight: 700;
            font-size: var(--font-nav-tit);
            color: var(--color-grey-100);

            &:after {
              content: '';
              display: block;
              width: 100px;
              height: 40px;
              background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/ceo_sign.webp') no-repeat;
              background-size: Cover;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    & .ceo_sec {
      position: relative;
      padding: 70px 0 90px;

      & .ceo_box {
        margin-top: 70px;
        display: block;
        width: 100%;

        & > div {
          width: 100%;

          &.ceo_img img {
            width: 100%;
          }

          &.ceo_desc {
            margin-top: 30px;
            width: 100%;

            & .desc_in {
              position: relative;

              & p {
                font-size: var(--font-main-sub-tit);

                & + p {
                  margin-top: 15px;
                }
              }

              & span {
                position: relative;
                margin-top: 40px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 0 10px;
                font-weight: 700;
                font-size: var(--font-nav-tit);
                color: var(--color-grey-100);

                &:after {
                  content: '';
                  display: block;
                  width: 100px;
                  height: 40px;
                  background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/ceo_sign.webp') no-repeat;
                  background-size: Cover;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    & .ceo_sec {
      position: relative;
      padding: 35px 0 45px;

      & .ceo_box {
        margin-top: 30px;
        display: block;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    & .ceo_sec {
      & .ceo_box {
        & > div {
          &.ceo_desc {
            & .desc_in {
              & p {
                word-break: break-word;

                br {
                  display: none;
                }
              }
            }
          }
        }

      }
    }
`;

export default CompanyCeo;