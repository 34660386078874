export const SPIRAL_GASKETS = {
  normal: {
    type: 'Normal Type',
    items: [
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_01.webp',
        name: 'USS Spiral Gasket',
        desc: 'Medium Shielding EMI Gasket, Low Coast',
        list: [
          { label: 'Item Name', value: 'USS Spiral Gasket' },
          { label: 'Material Thickness', value: '0.03 ~ 2.00mm' },
          { label: 'Outer Diameter', value: '1.00 ~ 7.00mm' },
          { label: 'Part No', value: 'USS(Stainless Steel),\n USN(Stainless Steel + Tin Plated)' }
        ]
      },
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_03.webp',
        name: 'UTI-AL Spiral Gasket',
        desc: 'Aluminum Plated, Ground Connection',
        list: [
          { label: 'Item Name', value: 'UTI-AL Spiral Gasket' },
          { label: 'Material Thickness', value: '0.08 ~ 2.00mm' },
          { label: 'Outer Diameter', value: 'Plated\n (0.002mm ~ 0.005mm)' },
          { label: 'Part No', value: 'Plated\n (0.002mm ~ 0.005mm)' }
        ]
      },
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_04.webp',
        name: 'UEL Spiral Gasket',
        desc: 'Ground Connection, Anti Arcing',
        list: [
          { label: 'Item Name', value: 'UEL Spiral Gasket' },
          { label: 'Material Thickness', value: '0.05 ~ 2.00mm' },
          { label: 'Outer Diameter', value: '2.00 ~ 7.00mm' },
          { label: 'Part No', value: 'UEL(Elgiloy)' }
        ]
      },
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_05.webp',
        name: 'UCS Spring Gasket',
        desc: 'Stainless Steel Coil',
        list: [
          { label: 'Item Name', value: 'UCS Spring Gasket' },
          { label: 'Material Thickness', value: '0.20 ~ 0.50mm' },
          { label: 'Outer Diameter', value: '1.50 ~ 4.00mm' },
          { label: 'Part No', value: 'UCL(Stainless Steel)' }
        ]
      },
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_06.webp',
        name: 'UCH Spring Gasket',
        desc: 'Hastelloy Coil',
        list: [
          { label: 'Item Name', value: 'UCH Spring Gasket' },
          { label: 'Material Thickness', value: '0.20 ~ 0.50mm' },
          { label: 'Outer Diameter', value: '1.50 ~ 4.00mm' },
          { label: 'Part No', value: 'UCH(Hastelloy)' }
        ]
      },
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_07.webp',
        name: 'UCB Spring Gasket',
        desc: 'BeCu Coil',
        list: [
          { label: 'Item Name', value: 'UCB Spring Gasket' },
          { label: 'Material Thickness', value: '0.20 ~ 0.50mm' },
          { label: 'Outer Diameter', value: '1.50 ~ 4.00mm' },
          { label: 'Part No', value: 'UCB(BeCu)' }
        ]
      },
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/spiral_08.webp',
        name: 'UCI Spring Gasket',
        desc: 'Inconel Coil',
        list: [
          { label: 'Item Name', value: 'UCI Spring Gasket' },
          { label: 'Material Thickness', value: '0.20 ~ 0.50mm' },
          { label: 'Outer Diameter', value: '1.50 ~ 4.00mm' },
          { label: 'Part No', value: 'UCI(Inconel)' }
        ]
      }
    ]
  },
  form: {
    type: 'Form Type',
    items: [
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/form_01.webp',
        name: 'UBC Spiral Gasket\n(Paste Silicon Rubber)',
        desc: 'Highest Shielding EMI Gasket Tin, Nickel Plated',
        list: [
          { label: 'Item Name', value: 'UBC Spiral Gasket(Paste Silicon Rubber)' },
          { label: 'Material Thickness', value: '0.03 ~ 2.00mm' },
          { label: 'Outer Diameter', value: '1.00 ~ 7.00mm' },
          { label: 'Part No', value: 'UBC-SN(BeCu + Tin),\n UBC-NI(BeCu + Nickel)' }
        ]
      },
      {
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/form_02.webp',
        name: 'UBC Spiral Gasket\n(Extrudable Silicone Rubber)',
        desc: 'Highest Shielding EMI Gasket Tin, Nickel Plated',
        list: [
          { label: 'Item Name', value: 'UBC Spiral Gasket(Extrudable Silicone Rubber)' },
          { label: 'Material Thickness', value: '0.03 ~ 2.00mm' },
          { label: 'Outer Diameter', value: '1.00 ~ 7.00mm' },
          { label: 'Part No', value: 'UBC-SN(BeCu + Tin),\n UBC-NI(BeCu + Nickel)' }
        ]
      },
    ]
  }
};

export const PAD_GASKETS = [
  {
    image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/pad_02.webp',
    name: 'Poly Pad',
    desc: '',
    info: 'solution.pad.poly.sub',
    list: [
      { label: 'Item Name', value: 'Poly Pad' },
      { label: 'Material Thickness', value: '0.152~0.228mm' },
      { label: 'Temperature', value: '-60 ~ 180℃' },
      { label: 'Characteristic', value: 'solution.pad.poly.part' }
    ]
  },
  {
    image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/pad_01.webp',
    name: 'Polymer Gel Pad',
    desc: '',
    info: 'solution.pad.polymer.sub',
    list: [
      { label: 'Item Name', value: 'Polymer Gel Pad' },
      { label: 'Material Thickness', value: '0.15 ~ 1mm' },
      { label: 'Temperature', value: '-60 ~ 180℃' },
      { label: 'Characteristic', value: '1 ~ 5W/Mk' }
    ]
  },
  {
    image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/pad_03.webp',
    name: 'Graphite Pad',
    desc: 'Aluminum Plated, Ground Connection',
    info: 'solution.pad.graphite.sub',
    list: [
      { label: 'Item Name', value: 'Graphite Pad' },
      { label: 'Material Thickness', value: '0.381mm' },
      { label: 'Temperature', value: '~ 450℃' },
      { label: 'Characteristic', value: 'solution.pad.graphite.part' }
    ]
  },
];