import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  debug: true,
  lng: 'ko',
  fallbackLng: 'ko',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ko: {
      translation: { // 번역본 쓸 공간
        header: {
          company: {
            outline: '회사개요',
            ceo: 'CEO인사',
            history: '연혁',
            business: '주요사업',
            certificate: '인증서',
            globalBusiness: '해외법인',
            wayToCome: '오시는길',
          },
          recruit: {
            talent: '인재상',
            benefit: '복리후생',
          },
          support: {
            notice: 'UST News',
            consult: '1:1 상담 / 견적',
            reference: '자료실',
          },
          language: {
            ko: 'KOR',
            en: 'ENG',
            cn: 'CHN',
          },
        },
        footer: {
          info: '(주)유에스티 대표이사 방민규',
          address: '경기도 화성시 동탄산단 2길 50',
          businessNum: '사업자번호 124-87-20754',
        },
        main: {
          company: {
            title: '유에스티의 끊임없는 혁신, 강한 실행력으로\n우리의 가치를 실현합니다.',
            outline: {
              title: '회사개요',
              sub: '최고의 솔루션을 제공하는 기업'
            },
            ceo: {
              title: 'CEO인사',
              sub: '자리이타의 행복경영 이념'
            },
            history: {
              title: '연혁',
              sub: '설립과 제품의 혁신'
            },
            business: {
              title: '주요사업',
            },
          },
          solution: {
            title: {
              first: '<em>유에스티</em>의 Gasket이 궁금하신가요?\n언제든지 연락주세요.',
            },
            btn: '문의하기'
          },
          gasket: {
            spiral: {
              first: 'Gasket 제조방법 및 3종 특허보유',
              second: '50여 종 개발 및 국내외 공급',
              third: 'Gasket 자체생산',
            },
            pad: {
              first: 'Q & Gel-PAD 소재 자체생산',
              second: 'Q PAD 0.152~0.4mm 소재생산',
              third: '고객사 사양에 맞춤생산 가능',
            }
          },
          recruit: {
            banner: {
              title: '임직원들이 함께 만드는 행복한 일터를\n추구하는 <em>유에스티</em>입니다.',
              sub: '유에스티와 함께 미래를 선도할 인재를 찾습니다'
            },
            title: '유에스티와 함께 고객의 행복한 성공을\n만들어나갈 인재를 찾습니다.',
            btn: '바로가기'
          }
        },
        company: {
          outline: {
            title: '고객의 행복을 위해 미래를 준비합니다.',
            sub: {
              first: 'UST(Universal Solution Technology)는 국내외 고객에게 최고의 Solution을 제공하여\n고객의 이익창출 및 기술발전이 우선이라는 경영이념을 바탕으로 창립하였습니다.',
              second: '자리이타(自利利他)',
              third: '남을 이롭게 함으로서 나도 이롭게 된다는 행복경영 이념을 바탕으로 끊임없이 노력하고 정진했습니다.'
            },
            outline: {
              first: {
                title: '회사명',
                sub: '(주)유에스티'
              },
              second: {
                title: '대표이사',
                sub: '방민규'
              },
              third: {
                title: '설립일',
                sub: '2008년 11월 11일'
              },
              fourth: {
                title: '업체종목',
                sub: '제조업, 반도체장비,\n전자상거래업'
              },
              fifth: {
                title: '본사',
                sub: '경기도 화성시\n동탄산단2길 50'
              },
            },
            info: {
              first: {
                title: '고객의 행복을 위해 미래를 준비하는 기업',
                sub: '남을 이롭게 함으로서 나도 이롭게 된다는 자리이타의 행복경영 이념을 바탕으로 고객에게 행복한 성공을 돕는 회사'
              },
              second: {
                title: '해외법인을 보유한 기업',
                sub: 'GLOBAL 반도체 제조 업체로 해외법인을 보유한 회사'
              },
              third: {
                title: '고객에게 최고의 솔루션을 제공하는 기업',
                sub: '고객에게 최고의 솔루션을 제공해 고객의 이익 창출 및 기술 발전에 이바지하는 회사'
              },
              fourth: {
                title: '고객에게 최고의 기술을 제공하는 기업',
                sub: '반도체 장비 개조, 개선 및 부품 국산화, EMI SHIELD GASKET 제조, 초발수 코팅, 파레트 제품 유통 및 제작, 반도체 부품 및 기타제품 무역서비스를 제공하는 회사'
              }
            },
            organization: {
              title: '조직구성',
              sub: 'UST의 다양한 전문가들은 각자의 역할과 책임을 다하며 공동의 목표를 위해 긴밀하게 협력합니다.\n조직 구성원들 간의 연결과 상호작용을 중시하며 유연한 조직문화를 위한 운영 및 지원에 힘쓰고 있습니다.',
              img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_chart.webp',
            }
          },
          ceo: {
            title: '저희 (주)유에스티에 관심을 갖고 홈페이지에 방문해주신\n모든 분들께 진심으로 감사의 인사 드립니다.',
            greeting: {
              first: '고객의 성공을 돕고 직원의 행복을 추구하는 건강한 기업이 되고자\n최선의 노력을 다하고있는 ㈜유에스티 대표이사 방민규 입니다.',
              second: '2008년 창립 이래 남을 이롭게 함으로서 나도 이롭게 된다는\n自利利他[자리이타]의 행복경영 이념을 바탕으로\n고객의 행복한 성공을 돕기위해 끊임없이 노력하고 정진했습니다.',
              third: '앞으로도 전 임직원은 이익극대화가 아닌 다양한 이해관계자의 행복극대화를\n목적으로 하는 행복경영 이념을 토대로 새로운 연구와 창조적 개발능력을 갖춰\n기술 개발에 앞서 나갈 것을 약속드리며, 투명하고 합리적인 경영을 구축하여\n신뢰의 기업으로 성장하도록 노력하겠습니다.',
              fourth: '앞으로도 여러분들의 지속적인 관심으로 ㈜유에스티가 발전하는 힘이 되어 주십시오.\n감사합니다.',
              fifth: '대표이사 방민규',
            }
          },
          history: {
            title: '유에스티가 걸어온 역사',
            sub: '세계 최고의 기업으로 성장을 위한 노력을 지속하고 있습니다.\n더 나은 미래를 향한 유에스티의 도전, 유에스티의 새로운 역사는 이제부터 시작입니다.',
            twentyFour: {
              first: '고용노동부 주관 ‘2024년 청년친화강소기업(3년 연속)‘ 선정',
              second: '삼성전자 ‘Best Contribution Award 2023’ 수상'
            },
            twentyThree: {
              first: '벤처기업협회 주관 ‘우수벤처기업‘ 선정',
              second: '중소벤처기업부 주관 ‘미래성과공유기업’ 선정',
              third: 'ISO27001 / ISO45001 인증취득',
              fourth: '삼성전자 ‘Best Contribution Award 2022’ 수상',
            },
            twentyTwo: {
              first: {
                first: '메인비즈협회 주관 ‘중소벤처기업부 장관상’ 수상',
                second: '한국무역협회 주관 ‘무역의 날 수출의 탑 100만 불’ 선정',
                third: '경기도 경제과학진흥원 주관 ‘경기도 유망중소기업’ 선정',
              },
              second: '동탄 산업단지 신사옥 이전',
              third: '중소벤처기업부 주관 ‘인재육성형 중소기업’ 선정',
              fourth: {
                first: '중소기업중앙회 주관 ‘중소기업 유공자포상‘ 국무총리 표창',
                second: '‘2022년 대한민국 일자리 으뜸기업’ 대통령상 표창',
              },
              fifth: '화성상공회의소 주관 ‘모범상공인’ 화성시 국회의원 표창',
              sixth: {
                first: '중소벤처기업부 주관 ‘성과공유기업’ 선정',
                second: '중소벤처기업부 주관 ‘수출유망 중소기업‘ 선정'
              },
              img: {
                first: '기능의 혁신',
                second: '현재'
              }
            },
            twentyOne: {
              first: {
                first: '경기도 주관 ‘경기도 일자리 우수기업’',
                second: '경기도 주관 ‘면접수당 지급기업‘',
                third: '산업통상자원부 주관 ‘혁신기업 국가대표 1000’',
                fourth: '한국산업 기술평가관리원 주관 ‘소재·부품 ·장비 전문기업‘',
              },
              img: {
                first: '기능의 발전',
              }
            },
            nineteen: {
              first: '중소기업 우수성과 R&D 성과확인서 취득'
            },
            fifteen: {
              first: '기업부설연구소 설립'
            },
            fourteen: {
              first: '삼성전자 정식 등록 업체 취득',
              second: '㈜유에스티 법인 전환',
            },
            twelve: {
              first: '삼성반도체 정식 업체 등록 (IMK업체등록)'
            },
            eleven: {
              first: '벤처 / ISO 9001, ISO 14001 인증 취득'
            },
            nine: {
              first: 'PSK Equipment VAT Valve Sensor Module 개발',
              second: 'LAM Equipment De-chucking System 개발',
            },
            eight: {
              first: '유에스티 설립',
              img: {
                first: '설립과 제품의 혁신',
              }
            }
          },
          business: {
            title: '주요사업',
            sub: '전문 인력은 물론 축적된 경험과 기술력을 바탕으로 고객 감동과 만족을\n선사하고자 최선의 노력을 다할 것입니다.',
            spiral: {
              first: '가스켓 제조방법 및 3종 특허보유',
              second: '50여 종 개발 및 국내외 공급',
              third: '스파이럴 / 스프링 / 심지 가스켓 / 심지 본딩 가스켓 자체생산',
            },
            pad: {
              first: 'Q & Gel-PAD 소재 자체생산',
              second: 'Q PAD 0.152~0.4mm 소재생산',
              third: '고객사 사양에 맞춤생산 가능',
            },
            equipment: {
              first: 'AMAT. LAM. TEL 등 다양한 반도체 업체 개조개선 기술력 보유',
              second: '개조개선 아이템 특허 6종 보유',
            },
            smartFactory: {
              first: '스마트 팩토리',
              second: '세계 최고의 기업으로 성장을 위한 노력을 지속하고 있습니다.\n더 나은 미래를 향한 유에스티의 도전, 유에스티의 새로운 역사는 이제부터 시작입니다.',
              img: {
                first: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/smart_factory01.webp',
                second: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/smart_factory02.webp'
              },
              step: {
                first: '자체 엠이에스 시스템 원부자재 입출고 및 고객사 안전재고 관리',
                second: '설비 생산관리, 품질관리, 모니터링 시스템 구축',
                third: 'MES에서 출하 요청에 의한 해당 제품정보(바코드) 리딩으로 제품 출고',
              }
            }
          },
          certificate: {
            title: {
              first: '인증서',
              second: '수상내역'
            },
            banner: {
              first: '유에스티는 16개의 특허를 보유하고 있습니다.',
              second: 'UST만의 끊임없는 연구개발을 통해 기술력을 높일 계획을 합니다.'
            },
            info: {
              first: '인증기관',
              second: '사업장'
            },
          },
          globalBusiness: {
            cate: '주요 비즈니스',
            address: 'Address',
            tel: 'Tel',
            vietnam: {
              business: '화장품 사업, 오피스 임대',
              page: 'VINA 홈페이지'
            },
            india: {
              business: '방역, 시큐리티, 아웃소싱',
              page: {
                first: '방역 홈페이지',
                second: '시큐리티 홈페이지',
              }
            }
          },
          wayToCome: {
            title: {
              first: '찾아오시는 길',
              second: '유에스티로 찾아오시는 길을 자세히 알려드립니다.'
            },
            address: '경기도 화성시 동탄산단 2길 50, 유에스티 본사'
          }
        },
        solution: {
          catalogue: '카탈로그 다운로드',
          spiral: {
            title: 'UST만의 Spiral Gasket을 소개합니다.',
            application: {
              first: 'RF PLASMA 발생부위 및 MICRO WAVE 사용으로 NOISE 피해 우려 부위',
              second: '각종 장비의 전자파에 의한 신호간섭 발생 부위',
              third: '부품간 접촉력 향상과 접지용으로도 사용'
            },
            advantage: {
              first: '50여 종 이상의 부품 개발을 통한 제조 기술 보유',
              second: '반도체 제조 회사 및 반도체 장비 회사를 통한 검증된 품질 보증',
              third: '고객의 요청 사항으로 특주 가능'
            },
          },
          pad: {
            title: 'UST만의 Pad Gasket을 소개합니다.',
            poly: {
              sub: '전기적인 특성 및 열전도도 유지 및\n Scratch & Arcing 방지',
              part: 'Al Foil에 열전도성이\n 우수한 Polymer 양면 코팅'
            },
            polymer: {
              sub: 'Water edge 수율 향상 및 Arcing방지\n' + 'ㅤ',
              part: '1 ~ 5W/Mk'
            },
            graphite: {
              sub: 'T.C.P Gasket으로 고온에서 높은 열전도율\n 내열 충격성 부위 적합',
              part: '낮은 열팽창 계수, 높은 연전도율 &\n 내열 충격성 & 전기 전도성/ 내화학성'
            },
          }
        },
        recruit: {
          talent: {
            title: {
              first: '인재상',
              second: '채용절차'
            },
            sub: {
              first: '(주)유에스티는 GLOBAL 반도체 제조업체로 꿈과 열정이 있는 회사입니다.\n UST와 함께 미래산업을 이끌어갈 인재를 찾습니다.',
              second: '(주)유에스티의 미래에 당신을 초대합니다.'
            },
            img: {
              first: '변화를 두려워하지 않는 인재',
              second: '전문지식과 다양성을 보유한 인재',
              third: '남들과 다른 창의성과 열정적인 인재',
              fourth: '글로벌 감각과 리더쉽을 갖춘 인재',
            },
            icon: {
              first: '서류제출',
              second: '1차 면접',
              third: '임원면접',
              fourth: '최종합격',
            },
            step: {
              first: '채용분야 : 기술영업 / 설계 / 반도체 장비 ENGINEER 상시채용',
              second: '신입사원은 채용 후 별도의 수습기간을 적용합니다.',
              third: '채용문의 : 경영지원팀',
              fourth: '연락처 :',
            }
          },
          benefit: {
            title: {
              first: '법정 복리후생',
              second: '법정 외 복리후생'
            },
            sub: {
              first: '산재보험, 국민연금, 건강보험, 고용보험 법정 4대보험',
              second: '다양한 복리후생 제도를 운영하여,\n 구성원들의 만족스러운 회사 생활과 풍요로운 삶을 적극 지원하고 있습니다.'
            },
            legal: {
              first: '산재보험',
              second: '국민연금',
              third: '건강보험',
              fourth: '고용보험',
            },
            extra: {
              first: '명절 상여 및 성과금 지원',
              second: '교육비 지원',
              third: '리조트 지원',
              fourth: '워크샵 지원',
              fifth: '근로자 복지카드 지급',
              sixth: '각종 경조 지원금',
              seventh: '건강검진 지원',
              eighth: '단체 상해보험 가입',
              ninth: '기념일 상품권, 유급휴가',
              tenth: '팀별 간담회/회식비 지원',
              eleventh: '월 1회 GWP DAY',
              twelfth: '안마의자 등 휴게시설',
              thirteenth: '사내식당 완비',
              fourteenth: '샤워장 완비',
              fifteenth: '사내헬스장 및 탁구장 완비',
              sixteenth: '무료 사내 편의점',
            }
          }
        },
        support: {
          consult: {
            title: '1:1 상담 / 견적',
            sub: '견적 등 원하시는 문의가 있을 경우 내용을 남겨주세요',
            label: {
              first: '이름(회사명)',
              second: '연락처',
              third: '이메일',
              fourth: '제목',
              fifth: '내용',
              sixth: '첨부파일',
              seventh: '개인정보 수집 및 이용 동의 약관',
              eighth: '[필수] 개인정보 수집 및 이용 동의',
            },
            placeholder: {
              first: '이름(회사명)을 입력해주세요.',
              second: '‘-’ 없이 숫자만 입력해주세요.',
              third: '이메일을 입력해주세요.',
              fourth: '제목을 입력해주세요.',
              fifth: '내용을 입력해주세요.',
              sixth: '10MB 이하의 JPG, GIF, PNG, PDF 파일만 첨부 가능합니다.',
            },
            alert: {
              first: '연락처를 확인해 주세요.',
              second: '이메일을 확인해 주세요.',
              third: '개인정보 수집 및 이용 동의 약관을 동의해 주세요.',
              fourth: '등록되었습니다.',
              fifth: '파일 크기는 10MB를 초과할 수 없습니다.',
              sixth: '허용되지 않는 파일 형식입니다.',
              seventh: '업로드에 실패하였습니다. 잠시후에 다시 시도해주세요.',
            },
            btn: {
              first: '파일 선택',
              second: '업로드',
              third: '등록하기',
              fourth: '취소하기',
            }
          },
          notice: {
            title: 'UST News',
            sub: ''
          },
          reference: {
            title: '자료실',
            sub: '(주)유에스티의 자료실입니다.'
          },
          board: {
            page: {
              first: '총',
              second: '건',
              third: '페이지'
            },
            table: {
              first: '번호',
              second: '제목',
              third: '작성자',
              fourth: '조회',
            },
            detail: {
              list: '목록',
              before: '이전글',
              after: '다음글'
            }
          }
        }
      },
    },
    en: {
      translation: { // 번역본 쓸 공간
        header: {
          company: {
            outline: 'Overview',
            ceo: 'CEO greetings',
            history: 'History',
            business: 'Main Business',
            certificate: 'Certifications',
            globalBusiness: 'Overseas Corporation',
            wayToCome: 'Way To Come',
          },
          recruit: {
            talent: 'Type of talent',
            benefit: 'Benefits',
          },
          support: {
            notice: 'Notice',
            consult: '1:1 Consultation / Estimate',
            reference: 'Reference',
          },
          language: {
            ko: 'KOR',
            en: 'ENG',
            cn: 'CHN',
          },
        },
        footer: {
          info: 'Bang Min-kyu, CEO of UST',
          address: '50, Dongtansandan 2-gil, Hwaseong-si, Gyeonggi-do, Republic of Korea',
          businessNum: 'Business number 124-87-20754',
        },
        main: {
          company: {
            title: 'UST Co.,Ltd realize our values through \n constant innovation and strong execution.',
            outline: {
              title: 'Overview',
              sub: 'A company that proposes the best solution'
            },
            ceo: {
              title: 'CEO greetings',
              sub: 'Happiness Management Philosophy'
            },
            history: {
              title: 'History',
              sub: 'Establishment & Product innovation'
            },
            business: {
              title: 'Main Business',
            },
          },
          solution: {
            title: {
              first: 'Are you curious about <em>UST</em>`s gaskets? \n Please feel free to contact us anytime.',
            },
            btn: 'Contact us'
          },
          gasket: {
            spiral: {
              first: 'Gasket Manufacturing method & 3types Patent acquisition',
              second: 'Development of more than 50 types and domestic and international supply',
              third: 'Gasket In-house production',
            },
            pad: {
              first: 'Q & Gel-PAD material In-house production',
              second: 'Q PAD 0.152~0.4mm material In-house production',
              third: 'Customized production to customer specifications',
            }
          },
          recruit: {
            banner: {
              title: '<em>UST</em> pursues a satisfied workplace created\n by all employees together.',
              sub: 'We are looking for talented people who will lead the future with UST.'
            },
            title: 'We are looking for talented people who will work with\nUST to create pleased success for customers.',
            btn: 'Learn More'
          }
        },
        company: {
          outline: {
            title: 'We aer ready for the future for our customers` happiness.',
            sub: {
              first: 'UST(Universal Solution Technology)was founded based on the management philosophy that \n customer profit creation and technological development are priorities by providing \n the best solutions to domestic and international customers.',
              second: '自利利他',
              third: 'Based on the happy management ideology that I also benefit by benefiting others, I have constantly tried and devoted myself.'
            },
            outline: {
              first: {
                title: 'Company name',
                sub: 'UST'
              },
              second: {
                title: 'CEO',
                sub: 'Bang Min-kyu'
              },
              third: {
                title: 'Date of Establishment',
                sub: 'November 11, 2008'
              },
              fourth: {
                title: 'Business category',
                sub: 'Manufacturing. \n Semiconductor equipment. \n E-commerce.'
              },
              fifth: {
                title: 'Address',
                sub: '50, Dongtansandan 2-gil,\nHwaseong-si, Gyeonggi-do,\nRepublic of Korea'
              },
            },
            info: {
              first: {
                title: 'A company preparing for the future for customer happiness',
                sub: 'A company that helps customers achieve happiness and success based on 自利利他 happiness management philosophy that by benefiting others, we also benefit.'
              },
              second: {
                title: 'Companies with overseas branches',
                sub: 'A global semiconductor manufacturer with overseas branches'
              },
              third: {
                title: 'A company that offers the best solutions to customers',
                sub: 'A company that provides the best solutions to customers and contributes to their profits and technological advancement'
              },
              fourth: {
                title: 'A company that provides the best technology to customers',
                sub: 'A company that provides semiconductor equipment remodeling, improvement and local production of parts, EMI SHIELD GASKET manufacturing, super water-repellent coating, distribution and production of pallet products, and trade services for semiconductor parts and other products'
              }
            },
            organization: {
              title: 'Organizational composition',
              sub: 'UST`s diverse experts fulfill their roles and responsibilities and work closely together for a common goal. \n We value connection and interaction between organization members and strive to operate and support a flexible organizational culture.',
              img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_chart_en.webp',
            }
          },
          ceo: {
            title: 'I would like to express my sincere gratitude to everyone \n who is interested in UST Co., Ltd. and visited our website.',
            greeting: {
              first: 'This is Bang Min-kyu, CEO of UST. I have been doing my best to make \n UST a healthy company that helps customers succeed and \npursues employee welfare.',
              second: 'Since its foundation in 2008, UST has been making persistent efforts and \n endeavors to help our customers\' success based on “自利利他”, \n self-help management philosophy, which aims to benefit others as well. ',
              third: 'All the executives and employees of UST promise to take the lead in \n technology development through new research and creative development \n capabilities based on the philosophy of self-help management, \n which aims to maximize the satisfaction of various stakeholders, \n not profit maximization. \n We will strive to grow into a company of trust by establishing transparent \n and reasonable management.',
              fourth: 'Please continue to be a force for UST to develop with your continued interest. \n Thank you.',
              fifth: 'Bang Min-kyu, CEO of UST',
            }
          },
          history: {
            title: 'The history of the UST',
            sub: 'We are continuing our efforts to grow as the world`s best company. \n UST`s challenge to a better future, UST`s new history begins from now on.',
            twentyFour: {
              first: 'Selected by the Ministry of Employment and Labor as "2024 Youth-Friendly Small and Medium Enterprises (3 consecutive years)"',
              second: 'Samsung Electronics ‘Best Contribution Award 2023’',
            },
            twentyThree: {
              first: '‘Best Venture Company‘',
              second: 'Future performance sharing Company’',
              third: 'ISO27001 / ISO45001 Certifications',
              fourth: 'Samsung Electronics ‘Best Contribution Award 2022’',
            },
            twentyTwo: {
              first: {
                first: '‘Commendation of Prime Minister’',
                second: 'KITA ‘Million Dollar Export Award’',
                third: '‘Gyeonggi-do Promising SME’',
              },
              second: 'Relocate new Dongtan company building',
              third: '‘SME for Human Resources Development’ Designation',
              fourth: {
                first: '‘Commendation of Prime Minister‘',
                second: '‘2022 Best Job Enterprise Award’ in Korea from President’',
              },
              fifth: '‘Best Business Owner’ Designation‘',
              sixth: {
                first: '‘Performance –sharing Enterprise’',
                second: '‘Promising exporting SME‘'
              },
              img: {
                first: 'Functional innovation',
                second: 'The present'
              }
            },
            twentyOne: {
              first: {
                first: '‘Gyeonggi-do Best Job Enterprise Award’',
                second: '‘Interview Allowance Payment Enterprise‘',
                third: '‘National Representative of Innovative Companies1000’',
                fourth: '‘Specialized Enterprise of materials, parts and equipment‘',
              },
              img: {
                first: 'The development of functions',
              }
            },
            nineteen: {
              first: 'Obtain confirmation of SME excellence and R&D performance'
            },
            fifteen: {
              first: 'Establishment of an enterprise-affiliated research institute'
            },
            fourteen: {
              first: 'Samsung Electronics officially registered as a partner',
              second: 'Convert to the Corporation “UST Co.,Ltd.”',
            },
            twelve: {
              first: 'Samsung Semiconductor officially registered as a partner'
            },
            eleven: {
              first: 'Venture Company / ISO9001, ISO14001 Certification'
            },
            nine: {
              first: 'PSK Equipment VAT Valve Sensor Module development',
              second: 'LAM Equipment De-chucking System development',
            },
            eight: {
              first: 'Establish UST Co., Ltd',
              img: {
                first: 'Establishment and product innovation',
              }
            }
          },
          business: {
            title: 'Main Business',
            sub: 'We will do our best to impress and satisfy customers based on \n our accumulated experience and technology as well as professionals.',
            spiral: {
              first: 'Gasket Manufacturing method & 3types Patent acquisition',
              second: 'Development of more than 50 types and domestic and international supply',
              third: 'spiral / spring / wick gasket / wick bonding gasket In-house production',
            },
            pad: {
              first: 'Q & Gel-PAD material In-house production ',
              second: 'Q PAD 0.152~0.4mm material In-house production ',
              third: 'Customized to customer specifications',
            },
            equipment: {
              first: 'Technology to improve the renovation and improvement of various major companies such as AMAT. LAM. TEL',
              second: '6types of patent acquisition for retrofit improvement items',
            },
            smartFactory: {
              first: 'Smart Factory',
              second: 'We are continuing our efforts to grow as the world`s best company. \n UST`s challenge to a better future, UST`s new history begins from now on.',
              img: {
                first: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/smart_factory01_en.webp',
                second: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/smart_factory02_en.webp'
              },
              step: {
                first: 'In-house MES system raw and subsidiary materials and customer safety inventory management',
                second: 'Establishment of facility production management, quality management, and monitoring system',
                third: 'Product is shipped by reading the relevant product information (barcode) according to the shipment request from MES',
              }
            }
          },
          certificate: {
            title: {
              first: 'Certifications',
              second: 'Awards'
            },
            banner: {
              first: 'UST holds 16 patents.',
              second: 'We plan to increase our technology through UST`s constant research and development.'
            },
            info: {
              first: 'Certificate authority',
              second: 'Workplace'
            },
          },
          globalBusiness: {
            cate: 'Major Business',
            address: 'Address',
            tel: 'Tel',
            vietnam: {
              business: 'Cosmetic wholesale and retail, Shared Office Lease',
              page: 'VINA Homepage'
            },
            india: {
              business: 'Quarantine Solution, Security Solution, Manpower Outsourcing',
              page: {
                first: 'Quarantine Solution Homepage',
                second: 'Security Solution Homepage',
              }
            }
          },
          wayToCome: {
            title: {
              first: 'The way you come to me',
              second: 'I would like to give you a detailed way to come to UST.'
            },
            address: '50, Dongtansandan 2-gil, Hwaseong-si, Gyeonggi-do, Republic of Korea'
          }
        },
        solution: {
          catalogue: 'Download Catalog',
          spiral: {
            title: 'Introducing UST`s own Spiral Gasket.',
            application: {
              first: 'RF PLASMA Occurrence area and NOISE Damage area due to use of MICRO WAVE',
              second: 'The site of signal interference caused by electromagnetic waves in various equipment',
              third: 'Also used for improved contact between parts and grounding'
            },
            advantage: {
              first: 'Possesses manufacturing technology through development of over 50 types of semiconductor parts',
              second: 'Proven quality assurance through semiconductor manufacturing and equipment companies',
              third: 'Available personal order as a customer request',
            },
          },
          pad: {
            title: 'Introducing UST`s own Pad Gasket.',
            poly: {
              sub: 'Maintain electrical properties, thermal conductivity and prevent Scratch & Arcing',
              part: 'Polymer double-sided coating with excellent thermal conductivity on Al foil'
            },
            polymer: {
              sub: 'Improve water edge yield and prevent arcing',
              part: '1 ~ 5W/Mk'
            },
            graphite: {
              sub: 'T.C.P. Gasket Suitable for High Thermal Conductivity Heat Resistant Impact Areas at High Temperature',
              part: 'Low coefficient of thermal expansion, high dielectric conductivity & heat-resisting impact resistance & electrical conductivity/chemical resistance'
            },
          }
        },
        recruit: {
          talent: {
            title: {
              first: 'Type of talent',
              second: 'Recruitment procedures'
            },
            sub: {
              first: 'UST Co., Ltd. is a global semiconductor manufacturer with dreams and passion. \n We are looking for talent to lead the future industry with UST.',
              second: 'We invite you to the future of UST Co., Ltd.'
            },
            img: {
              first: 'A man who responds  \n without fear of change',
              second: 'A man who talents with  \n expertise and diversity',
              third: 'A man who talents with  \n discriminatory creativity and passion',
              fourth: 'A man who talents with  \n global sense and leadership',
            },
            icon: {
              first: 'Submission of documents',
              second: 'The first round of interviews',
              third: 'Interview with executives',
              fourth: 'Final pass',
            },
            step: {
              first: 'Full-time employment of : Technical sales / Design / semiconductor equipment ENGINEER',
              second: 'New employees will apply a probationary period after hiring.',
              third: 'Recruitment inquiry : Management Support Team',
            }
          },
          benefit: {
            title: {
              first: 'Legal Benefits',
              second: 'Out-of-court Benefits'
            },
            sub: {
              first: 'Industrial accident insurance, National pension, Health insurance, Employment insurance \n The four major insurances under the law',
              second: 'By operating various welfare systems, we actively support \n the satisfactory company life and convenient and rational life of our members.'
            },
            legal: {
              first: 'Industrial insurance',
              second: 'National pension',
              third: 'Health insurance',
              fourth: 'Employment insurance',
            },
            extra: {
              first: 'Support for holiday bonuses and incentives',
              second: 'Support for educational expenses',
              third: 'Resort Support',
              fourth: 'Workshop Support',
              fifth: 'Workers` welfare card Support',
              sixth: 'Various subsidies for family event support',
              seventh: 'Health examination Support',
              eighth: 'Group accident insurance subscription',
              ninth: 'Anniversary gift certificates, paid leave',
              tenth: 'Support for team meetings, dinner expenses',
              eleventh: 'Once a month GWP day',
              twelfth: 'Resting facilities such as massage chairs',
              thirteenth: 'Restaurant facilities in the office',
              fourteenth: 'Complete with shower facilities',
              fifteenth: 'Complete with an in-house gym and table tennis court',
              sixteenth: 'In-office convenience stores',
            }
          }
        },
        support: {
          consult: {
            title: '1:1 Consultation / Estimate',
            sub: 'If you have any questions such as estimates, please note the information.',
            label: {
              first: 'Name(company name)',
              second: 'Contact Information',
              third: 'E-mail',
              fourth: 'Title',
              fifth: 'Contents',
              sixth: 'The attached file',
              seventh: 'Terms and conditions of consent to collect and use personal information',
              eighth: '[Required] Consent to collect and use personal information',
            },
            placeholder: {
              first: 'Please enter your name (company name).',
              second: 'Please just enter the numbers without "-" them.',
              third: 'Please fill out your email.',
              fourth: 'Please fill out a title.',
              fifth: 'Please fill out the content.',
              sixth: 'You can only attach JPG, GIF, PNG, and PDF files that are less than 10MB.',
            },
            alert: {
              first: 'Please check your contact information.',
              second: 'Please check your email.',
              third: 'Please agree to the terms and conditions of consent to collect and use personal information.',
              fourth: 'Your registration is complete.',
              fifth: 'File size cannot exceed 10MB.',
              sixth: 'This is not allowed file type.',
              seventh: 'Upload failed. Please try again in a moment.',
            },
            btn: {
              first: 'Select File',
              second: 'Upload',
              third: 'Registration',
              fourth: 'Cancel',
            }
          },
          notice: {
            title: 'Notice',
            sub: 'This is the notice from UST Co., Ltd.'
          },
          reference: {
            title: 'Reference',
            sub: 'This is the archive of UST Co., Ltd.'
          },
          board: {
            page: {
              first: 'total',
              second: '',
              third: 'page'
            },
            table: {
              first: 'No',
              second: 'Title',
              third: 'Writer',
              fourth: 'View',
            },
            detail: {
              list: 'List',
              before: 'Previous',
              after: 'Next'
            }
          }
        }
      },
    },
    cn: {
      translation: { // 번역본 쓸 공간
        header: {
          company: {
            outline: '企业概要',
            ceo: 'CEO 语录',
            history: '主要沿革',
            business: '主要事业',
            certificate: '持有认证',
            globalBusiness: '海外法人',
            wayToCome: '未来的路'
          },
          recruit: {
            talent: '人才奖',
            benefit: '福利',
          },
          support: {
            notice: '公告事项',
            consult: '1:1 商谈/估价',
            reference: '资料室',
          },
          language: {
            ko: 'KOR',
            en: 'ENG',
            cn: 'CHN',
          },
        },
        footer: {
          info: '(株)UST代表理事 方珉奎',
          address: '50, Dongtansandan 2-gil, Hwaseong-si, Gyeonggi-do, Republic of Korea',
          businessNum: '营业执照号 124-87-20754',
        },
        main: {
          company: {
            title: '凭借UST坚持不懈的创新、强大的执行力实现我们的价值',
            outline: {
              title: '企业概要',
              sub: '提供最佳解决方案的企业'
            },
            ceo: {
              title: 'CEO 语录',
              sub: '[自利利他] 幸福经营理念'
            },
            history: {
              title: '主要沿革',
              sub: '创办与产品创新'
            },
            business: {
              title: '主要事业',
            },
          },
          solution: {
            title: {
              first: '想了解<em>UST</em>的Gasket吗？\n请随时联系我们。',
            },
            btn: '联系我们'
          },
          gasket: {
            spiral: {
              first: '持有 Gasket制造方法 和 3项专利',
              second: '50多种开发 及 国内外供应',
              third: 'Gasket自主生产',
            },
            pad: {
              first: 'Q & Gel-PAD 材料自主生产',
              second: 'Q PAD 0.152~0.4mm 材料生产',
              third: '可根据客户的规格量身定做',
            }
          },
          recruit: {
            banner: {
              title: '我是追求员工们一起创造幸福工作岗位的<em>UST</em>。 ',
              sub: '寻找 与UST一起引领未来的人才'
            },
            title: '寻找能够与UST一起创造客户幸福成功的人才。',
            btn: '快捷方式'
          }
        },
        company: {
          outline: {
            title: '为客户的幸福做好未来准备',
            sub: {
              first: 'UST(Universal Solution Technology)是即为国内外客户提供最好的解决方案，\n以创造客户利益和技术发展为先的 经营理念创立的',
              second: '自利利他',
              third: '以"有利於別人，我也受益"的幸福經營理念爲基礎，不斷努力和精進。'
            },
            outline: {
              first: {
                title: '公司名',
                sub: '(株)UST'
              },
              second: {
                title: '代表理事',
                sub: '方珉奎'
              },
              third: {
                title: '成立日期',
                sub: '2008年 11月 11日'
              },
              fourth: {
                title: '企业股票',
                sub: '制造业, 半导体设备, 电子商务业'
              },
              fifth: {
                title: '总社',
                sub: '50, Dongtansandan 2-gil, \n Hwaseong-si, Gyeonggi-do, Republic of Korea'
              },
            },
            info: {
              first: {
                title: '为顾客的幸福准备未来的企业',
                sub: '以 “自利利他” 的幸福经营理念为基础，帮助客户取得幸福成功的公司'
              },
              second: {
                title: '拥有海外法人的企业',
                sub: 'GLOBAL 半导体制造企业，拥有海外法人的公司'
              },
              third: {
                title: '为顾客提供最佳解决方案的企业',
                sub: '为客户提供最佳解决方案，为客户创造利润和技术进步做出贡献的公司'
              },
              fourth: {
                title: '一家为客户提供最高技术的公司',
                sub: '提供半导体设备改造、改进和零部件国产化、EMI SHIELD GASKET 制造、超拨水涂层、托盘产品流通和制造、半导体零部件和其他产品贸易服务的公司'
              }
            },
            organization: {
              title: '组织结构',
              sub: 'UST的各单位专家各司其职，为共同的目标紧密合作。注重组织成员之间的联系和互动，\n致力于运营和支持灵活的组织文化',
              img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_chart_cn.webp',
            }
          },
          ceo: {
            title: '向所有关心我们(株)UST 并访问我们主页的人表示衷心的感谢。',
            greeting: {
              first: '我是为帮助顾客成功，追求职员幸福的健康企业 而尽最大努力的 \n (株)UST代表理事 方珉奎.',
              second: '2008年创立以来，本着 \n 自利：以利己为主的修养 利他：以利他人为目的的行为 \n [自利利他] 幸福经营理念，为帮助顾客幸福成功 一直在不断努力和精进中.',
              third: '今后全体员工将 不以利益最大化为目的 而是以各种利益相关者的 幸福最大化 \n 为目的的幸福经营理念为基础，具备新的研究和创造性开发能力，在技术开发 \n 上领先 以及 构建透明合理的经营，努力成长为值得信赖的企业.',
              fourth: '今后请大家持续关注 成为(株)UST发展的力量. \n 谢谢.',
              fifth: '代表理事 方珉奎',
            }
          },
          history: {
            title: 'UST走过的历史',
            sub: '为成长为世界上最好的企业，我们一直在为发展而努力。\nUST向更美好未来的挑战，UST的新历史从现在开始',
            twentyFour: {
              first: '由雇佣劳动部主管 ‘2024年青年亲和强小企业(连续3年)‘ 善政',
              second: '获得 2023年 三星电子Best Contribution Award'
            },
            twentyThree: {
              first: '风险企业协会主办的 “优秀风险企业” 评选',
              second: '中小风险企业部主办的 “未来成果共享企业” 评选',
              third: '获得认证 ISO27001 / ISO45001',
              fourth: '获得 2022年 三星电子Best Contribution Award',
            },
            twentyTwo: {
              first: {
                first: '获得缅因商务协会主办的 “中小风险企业部长官奖“',
                second: '韩国贸易协会主办的 “贸易日出口之塔100万美元” 评选',
                third: '京畿道经济科学振兴院主办的 “京畿道有潜力的中小企业” 评选',
              },
              second: '搬迁至 Dongtan 产业园区新办公楼',
              third: '中小风险企业部主办的 “人才培养型中小企业” 评选',
              fourth: {
                first: '中小企业中央会主办的“ 中小企业有功者奖励” 国务总理表彰',
                second: '“2022年大韩民国就业第一企业”总统奖表彰',
              },
              fifth: '华城商工会议所主办的 “模范商工者” 华城市国会议员表',
              sixth: {
                first: '中小风险企业部主管的 “成果共享企业” 评选',
                second: '中小风险企业部主办的 “出口潜力中小企业” 评选'
              },
              img: {
                first: '技能革新',
                second: '现在'
              }
            },
            twentyOne: {
              first: {
                first: '评选 京畿道 优秀工作岗位企业',
                second: '评选 京畿道主办的 “面试津贴支付企业”',
                third: '产业通商资源部主办的 “创新企业国家代表1000” 评选',
                fourth: '评选 韩国产业技术评价管理院主办的“材料、零部件、设备专门企业”',
              },
              img: {
                first: '技能发展',
              }
            },
            nineteen: {
              first: '获得中小企业 优秀成果 研发成果确认书'
            },
            fifteen: {
              first: '设立企业附属研究所'
            },
            fourteen: {
              first: '三星半导体 官方公司注册',
              second: '(株)UST 成立法人公司',
            },
            twelve: {
              first: '三星半导体 官方公司注册(IMK企业注册)'
            },
            eleven: {
              first: '获得认证 / ISO9001, ISO14001'
            },
            nine: {
              first: 'PSK Equipment VAT Valve Sensor Module 开发',
              second: 'LAM Equipment De-chucking System 开发',
            },
            eight: {
              first: '成立 UST',
              img: {
                first: '创办与产品创新',
              }
            }
          },
          business: {
            title: '主要事业',
            sub: '我们将以专业人才和积累的经验和技术为基础，\n尽最大努力为客户带来感动和满足。',
            spiral: {
              first: '持有 Gasket制造方法 和 3项专利',
              second: '50多种开发 及 国内外供应',
              third: '自行生产 Spiral / Spring / Wick Gasket / Wick Bonding Gasket (要用英语)',
            },
            pad: {
              first: 'Q & Gel-PAD 材料自主生产',
              second: 'Q PAD 0.152~0.4mm 材料生产',
              third: '可根据客户的规格量身定做',
            },
            equipment: {
              first: '拥有 AMAT. LAM. TEL等多种major企业 改造改善技术能力',
              second: '拥有6项改造改善项目专利',
            },
            smartFactory: {
              first: '智能工厂',
              second: '为成长为世界上最好的企业，我们一直在为发展而努力。\nUST向更美好未来的挑战，UST的新历史从现在开始',
              img: {
                first: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/smart_factory01_cn.webp',
                second: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/smart_factory02_cn.webp'
              },
              step: {
                first: '自身MES系统原材料出入库及客户公司安全库存管理',
                second: '设备生产管理、质量管理、监控体系建设',
                third: '在MES中根据出货要求读取相应产品信息（条形码）后出库',
              }
            }
          },
          certificate: {
            title: {
              first: '持有认证',
              second: '获奖记录'
            },
            banner: {
              first: 'UST拥有16项专利。',
              second: '计划通过UST独有的不断研发提高技术能力。'
            },
            info: {
              first: '认证机构',
              second: '事业所'
            },
          },
          globalBusiness: {
            cate: '主业',
            address: '地址',
            tel: '电话号码',
            vietnam: {
              business: '化妆品批发零售，共享办公租赁',
              page: 'VINA 网页'
            },
            india: {
              business: '防疫解决方案, 安全解决方案, 人力外包',
              page: {
                first: '防疫 网页',
                second: '安全 网页',
              }
            }
          },
          wayToCome: {
            title: {
              first: '来访路线',
              second: '详细告诉您以UST来找您的路。'
            },
            address: '50, Dongtansandan 2-gil, Hwaseong-si, Gyeonggi-do, Republic of Korea'
          }
        },
        solution: {
          catalogue: '下载目录',
          spiral: {
            title: '介绍UST独有的Spiral Gasket。',
            application: {
              first: 'RF PLASMA发生部位和使用MICRO WAVE可能造成NOISE损失的部位',
              second: '各种设备的电磁波信号干扰发生部位',
              third: '用于提高部件间的接触力和接地'
            },
            advantage: {
              first: '拥有通过开发50多种部件的制造技术',
              second: '通过半导体制造公司和半导体设备公司 验证的质量保证',
              third: '可根据客户的要求进行特别订购'
            },
          },
          pad: {
            title: '介绍UST独有的Pad Gasket。',
            poly: {
              sub: '保持电气特性和热导率，\n防止 Scratch & Arcing',
              part: '在Al Foil上具有良好导热性的Polymer双面涂层'
            },
            polymer: {
              sub: '提高Water edge率 和 防止 Arcing',
              part: '1 ~ 5W/Mk'
            },
            graphite: {
              sub: '使用T.C.P Gasket在高温下 适合高热导率耐热冲击性部位',
              part: '低热膨胀系数，高热电导率&耐热冲击性&导电性/耐化学性'
            },
          }
        },
        recruit: {
          talent: {
            title: {
              first: '人才奖',
              second: '录用程序'
            },
            sub: {
              first: '(株)UST是GLOBAL半导体制造商，是一家有梦想和热情的公司。\n寻找与UST一起引领未来产业的人才。',
              second: '邀请您加入(株)UST的未来。'
            },
            img: {
              first: '不具怕变化的人才',
              second: '拥有专业知识和多样性的人才',
              third: '与众不同的创意和热情的人才',
              fourth: '具有全球意识和领导力的人才',
            },
            icon: {
              first: '交卷',
              second: '初次面试',
              third: '高管面试',
              fourth: '最终合格',
            },
            step: {
              first: '招聘领域 : 技术营业 / 设计 / 半导体设备 ENGINEER 长期招聘',
              second: '新职员在录用后适用单独的试用期。',
              third: '招聘咨询 : 经营支援组',
            }
          },
          benefit: {
            title: {
              first: '法定福利',
              second: '法定外福利'
            },
            sub: {
              first: '工伤保险, 国民年金, 健康保险, 雇佣保险 四大保险',
              second: '运营多种福利制度，\n积极支持成员满意的公司生活和丰富的生活。'
            },
            legal: {
              first: '工伤保险',
              second: '国民年金',
              third: '健康保险',
              fourth: '雇佣保险',
            },
            extra: {
              first: '节日奖金及奖金支援',
              second: '教育费支援',
              third: '度假村支援',
              fourth: '支援研讨会',
              fifth: '向劳动者发放福利卡',
              sixth: '各种红白喜事支援金',
              seventh: '支援健康检查',
              eighth: '团体人身意外伤害保险',
              ninth: '纪念日商品券，带薪休假',
              tenth: '各组恳谈会/会餐费支援',
              eleventh: '每月一次 GWP DAY',
              twelfth: '按摩椅等休息设施',
              thirteenth: '公司食堂完备',
              fourteenth: '淋浴间完备',
              fifteenth: '公司内部健身房及乒乓球场完备',
              sixteenth: '免费的公司内部便利店',
            }
          }
        },
        support: {
          consult: {
            title: '1:1 商谈/估价',
            sub: '如有报价等想要咨询的问题，请留言',
            label: {
              first: '姓名(公司名)',
              second: '联络处',
              third: '电子邮件',
              fourth: '题目',
              fifth: '内容物',
              sixth: '附件',
              seventh: '个人信息收集与使用同意条款',
              eighth: '【必需】同意收集及使用个人信息',
            },
            placeholder: {
              first: '请输入您的姓名(公司名称)',
              second: '没有“-”，只输入数字',
              third: '请输入电子邮件',
              fourth: '请输入标题',
              fifth: '请输入内容',
              sixth: '只能附加10MB以下的JPG、GIF、PNG、PDF文件',
            },
            alert: {
              first: '请确认一下联系方式',
              second: '请检查您的电子邮件',
              third: '请接受个人信息收集和使用同意条款',
              fourth: '已注册',
              fifth: '文件大小不能超过10MB',
              sixth: '不允许的文件类型',
              seventh: '上载失败。请稍后再试一次',
            },
            btn: {
              first: '选择文件',
              second: '上传',
              third: '注册',
              fourth: '撤消',
            }
          },
          notice: {
            title: '公告事项',
            sub: '(株)UST的公告事项'
          },
          reference: {
            title: '资料室',
            sub: '(株)UST的资料室。'
          },
          board: {
            page: {
              first: '整体',
              second: '件',
              third: '页'
            },
            table: {
              first: '号码',
              second: '题目',
              third: '制订者',
              fourth: '查询',
            },
            detail: {
              list: '目录',
              before: '上一篇文章',
              after: '下文'
            }
          }
        }
      },
    },
  },
}).then();

export default i18n;