export const SEO_DATA = {
  spiral: {
    title: 'UST Spiral Gasket',
    desc: 'UST의 Spiral Gasket 제품 라인을 소개 합니다. 각 Spiral Gasket의 특징과 장점을 확인해보세요.',
    keywords: 'Gasket, Spiral Gasket, EMI Gasket, EMI Spiral Gasket, Electromagnetic interference gasket, EMI Shielding Solutions, EMI Solutions, EMI Shielding Gasket',
    image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/layout/solution_top.webp',
    url: 'www.ust.co/solution/spiral',
  },
  pad: {
    title: 'UST Pad Gasket',
    desc: 'UST의 Pad Gasket 제품 라인을 소개 합니다. 각 Pad Gasket의 특징과 장점을 확인해보세요.',
    keywords: 'Gasket, Pad Gasket, Gasket Pad, Sealing Pad, Custom Pad Gasket, TIms, Thermal Interface Materials, Functional Gaskets',
    image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/layout/solution_top.webp',
    url: 'www.ust.co/solution/pad',
  }
};
