import React, { useRef, useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

/* swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import CompanyHead from 'components/Company/CompanyHead';

gsap.registerPlugin(ScrollTrigger);

const CompanyOutline = () => {
  const { t, i18n } = useTranslation();
  const FadeRef = useRef([]);
  const DelayRef = useRef([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [activeEng, setActiveEng] = useState(false);

  useEffect(() => {
    isLanguage(i18n.language);
  }, [i18n.language]);

  useDidMountEffect(() => {
    isLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    FadeRef.current.forEach((el) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          y: 150,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          scrollTrigger: {
            trigger: el,
            start: 'top 80%',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    });

    DelayRef.current.forEach((el, index) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          scale: 0.7,
        },
        {
          duration: 1,
          autoAlpha: 1,
          scale: 1,
          scrollTrigger: {
            trigger: el,
            start: 'top 80%',
            toggleActions: 'restart none none reverse',
          },
          delay: index * 0.15, // 딜레이 추가
        }
      )
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLanguage = lan => {
    switch (lan) {
      case 'en':
        setActiveEng(true);

        break;
      default:
        setActiveEng(false);

        break;
    }
  };
  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  const outLineItem = [
    {
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_ic01.svg',
      title: t('company.outline.outline.first.title'),
      desc: t('company.outline.outline.first.sub')
    },
    {
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_ic02.svg',
      title: t('company.outline.outline.second.title'),
      desc: t('company.outline.outline.second.sub')
    },
    {
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_ic03.svg',
      title: t('company.outline.outline.third.title'),
      desc: t('company.outline.outline.third.sub')
    },
    {
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_ic04.svg',
      title: t('company.outline.outline.fourth.title'),
      desc: t('company.outline.outline.fourth.sub')
    },
    {
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_ic05.svg',
      title: t('company.outline.outline.fifth.title'),
      desc: t('company.outline.outline.fifth.sub')
    },
  ];
  const outLineInfo = [
    {
      image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_info01.webp',
      title: t('company.outline.info.first.title'),
      desc: t('company.outline.info.first.sub')
    },
    {
      image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_info02.webp',
      title: t('company.outline.info.second.title'),
      desc: t('company.outline.info.second.sub')
    },
    {
      image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_info03.webp',
      title: t('company.outline.info.third.title'),
      desc: t('company.outline.info.third.sub')
    },
    {
      image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_info04.webp',
      title: t('company.outline.info.fourth.title'),
      desc: t('company.outline.info.fourth.sub')
    },
  ];

  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  };

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <CompanyHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* 회사 정보 */}
        <section className='outline_intro'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('company.outline.title')}</h4>
              <p>{formatData(t('company.outline.sub.first'))}</p>
              <p><em>{t('company.outline.sub.second')}</em> {formatData(t('company.outline.sub.third'))}</p>
            </div>
            <div className='outline_list'>
              <ul className={activeEng ? 'eng' : ''}>
                {outLineItem.map((item) => (
                  <li key={item.title} ref={el => addToRefs(el, DelayRef)}>
                    <div className='list_in'>
                      <div className='outline_ic'>
                        <OutlineIc src={item.ic}/>
                      </div>
                      <div className='outline_desc'>
                        <strong>{item.title}</strong>
                        <span>{item.desc}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        {/* 기업 소개 */}
        <section className='outline_info'>
          <div className='sec_inner'>
            {isMobile ? (
              <Swiper
                className='info_box'
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                pagination={true}
                modules={[Pagination]}
              >
                {outLineInfo.map((item) => (
                  <SwiperSlide className='info_item'>
                    <div className='item_in'>
                      <div className='item_bg'>
                        <OutlineImg src={item.image}/>
                      </div>
                      <div className='item_desc'>
                        <strong>{item.title}</strong>
                        <p>{item.desc}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <ul className='info_box'>
                {outLineInfo.map((item) => (
                  <li key={item.title} className='info_item' ref={el => addToRefs(el, FadeRef)}>
                    <div className='item_in'>
                      <div className='item_bg'>
                        <OutlineImg src={item.image}/>
                      </div>
                      <div className='item_desc'>
                        <strong>{item.title}</strong>
                        <p>{item.desc}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </section>
        {/* 조직도 */}
        <section className='outline_organization'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('company.outline.organization.title')}</h4>
              <p>{formatData(t('company.outline.organization.sub'))}</p>
            </div>
            <div className='chart' ref={el => addToRefs(el, FadeRef)}>
              {!isMobile && <img src={t('company.outline.organization.img')} alt='조직도'/>}
              {isMobile && <img src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/mo_organization.webp' alt='조직도'/>}
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  // 개요 Content
  .outline_intro {
    padding: 140px 0 220px;

    .outline_list {
      margin-top: 80px;

      & ul {
        display: flex;
        gap: 0 20px;

        &.eng li .list_in {
          height: 385px;
        }

        & li {
          width: calc(20% - 10px);

          & .list_in {
            padding: 50px 10px 30px;
            height: 335px;
            background: var(--color-grey-5);
            display: flex;
            flex-direction: column;
            align-items: center;

            & .outline_ic {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 116px;
              height: 116px;
              border-radius: 50%;
              background: var(--color-white);
            }

            & .outline_desc {
              margin-top: 40px;
              text-align: center;

              & strong {
                font-weight: 700;
                font-size: var(--font-nav-tit24);
                color: var(--color-grey-100);
              }

              & span {
                margin-top: 10px;
                display: block;
                font-weight: 500;
                font-size: var(--font-nav-sub-tit);
                line-height: 1.5;
                color: var(--color-grey-80);
                white-space: pre-line;
              }
            }
          }
        }
      }
    }
  }

  .outline_info {
    padding: 300px 0 150px;
    background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/outline_section_bg.webp') no-repeat;
    background-size: cover;

    & .info_box {
      position: relative;
      margin-left: auto;
      max-width: 1040px;
      display: flex;
      flex-wrap: wrap;
      gap: 0 100px;
      justify-content: flex-end;

      & .info_item {
        width: calc(50% - 50px);

        &:nth-child(even) {
          margin-top: 140px;
        }

        & .item_bg {
          position: relative;

          & img {
            width: 100%;
          }
        }

        & .item_desc {
          padding: 30px;
          background: #ffffff;
          min-height: 270px;

          & strong {
            font-weight: 700;
            font-size: var(--font-nav-tit24);
            color: var(--color-grey-100);
          }

          & p {
            margin-top: 25px;
            display: block;
            font-weight: 400;
            font-size: var(--font-page-s);
            line-height: 1.5;
            color: var(--color-grey-90);
          }
        }
      }
    }
  }

  .outline_organization {
    padding: 140px 0 180px;

    & .chart {
      margin-top: 90px;
      text-align: center;

      & img {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1024px) {

    // 개요 Content
    .outline_intro {
      padding: 70px 0 120px;

      .outline_list {
        margin-top: 70px;

        & ul {
          flex-wrap: wrap;
          gap: 15px 15px;

          &.eng li .list_in {
            height: 275px;
          }

          & li {
            width: calc(50% - 7.5px);

            & .list_in {
              padding: 35px 10px 15px;
              height: 210px;
              background: var(--color-grey-5);
              display: flex;
              flex-direction: column;
              align-items: center;

              & .outline_ic {
                width: 70px;
                height: 70px;
                min-width: 70px;
                min-height: 70px;

                & img {
                  width: 50%;
                }
              }

              & .outline_desc {
                margin-top: 20px;

                & span {
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }

    .outline_info {
      padding: 80px 0 50px;

      & .info_box {
        position: relative;
        margin: 0;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0 20px;
        justify-content: center;

        & .swiper-pagination {
          margin-top: 55px;
          position: relative;

          .swiper-pagination-bullet {
            background: #ffffff;
            opacity: 1;

            &.swiper-pagination-bullet-active {
              background: #539CD0;
            }
          }
        }

        & .info_item {
          width: 100%;

          &:nth-child(even) {
            margin: 0;
          }

          & .item_desc {
            padding: 15px 15px 25px;
            background: #ffffff;
            min-height: 155px;

            strong {
              font-size: var(--font-v-sub-tit);
            }

            p {
              margin-top: 15px;
              font-size: var(--font-nav-tit24)
            }
          }
        }
      }
    }

    .outline_organization {
      padding: 70px 0 90px;

      & .chart {
        margin-top: 30px;
        text-align: center;

        & img {
          width: 50%;
        }
      }
    }
  }

  @media only screen and (max-width: 720px) {

    .outline_organization {
      & .chart {
        & img {
          width: 75%;
        }
      }
    }
  }


  @media only screen and (max-width: 540px) {

    .outline_intro {
      padding: 35px 0 60px;
    }

    .outline_info {
      padding: 40px 0 25px;

      & .info_box {
        & .info_item {
          & .item_desc {
            min-height: 200px;

            strong {
              font-size: var(--font-nav-tit24);
            }

            p {
              font-size: var(--font-page-s)
            }
          }
        }
      }
    }

    .outline_organization {
      padding: 35px 0 45px;

      & .chart {
        & img {
          width: 100%;
        }
      }
    }
  }
`;

const OutlineIc = styled.img.attrs({ alt: '개요 아이콘' })``;
const OutlineImg = styled.img.attrs({ alt: '개요 이미지' })``;

export default CompanyOutline;