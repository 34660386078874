import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

// Data
import { PAD_GASKETS } from 'components/Solution/GasketData';
import SEOMeta from 'SEO/MetaTags';
import { SEO_DATA } from 'SEO/SEO_DATA';

// Components
import SolutionHead from 'components/Solution/SolutionHead';
import PadItem from 'components/Solution/PadItem';
import PadModal from 'components/Solution/PadModal';

/* swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

gsap.registerPlugin(ScrollTrigger);

const SolutionPad = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [selectItem, setSelectItem] = useState(null);
  const [isEng, setIsEng] = useState(false);

  const lang = {
    ko: false,
    en: true,
    cn: false
  };

  useDidMountEffect(() => {
    setIsEng(lang[i18n.language]);
  }, [i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (selectItem) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [selectItem]);

  const handleModal = (listItem) => {
    setSelectItem(listItem);
  };

  const getInfoText = (info) => {
    return info.startsWith('solution.') ? t(info) : info;
  };

  const getListText = (value) => {
    return value.startsWith('solution.') ? t(value) : value;
  };

  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  };

  return (
    <React.Fragment>
      <SEOMeta pageData={SEO_DATA.pad}/>
      {/* 루트별 공통 비주얼 헤더 */}
      <SolutionHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* 솔루션 섹션 */}
        <section className='solution_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>Pad Gasket Series</h4>
              <p>{t('solution.pad.title')}</p>
            </div>
            <div className='solution_con'>
              {!isMobile &&
                <ul className='pad_list'>
                  {PAD_GASKETS.map((listItem) =>
                    <PadItem key={listItem.name} {...listItem} />
                  )}
                </ul>
              }
              {isMobile &&
                <>
                  <Swiper className='pad_list' spaceBetween={12} slidesPerView={1.14} loop={true}>
                    {PAD_GASKETS.map((listItem) =>
                      <SwiperSlide className='list_item' key={listItem.name} onClick={() => handleModal(listItem)}>
                        <div className='item_in'>
                          <div className='item_img'>
                            <img src={listItem.image} alt={listItem.name}/>
                          </div>
                          <div className='item_desc'>
                            <strong>{listItem.name}</strong>
                            <span>{formatData(listItem.desc)}</span>
                            <p>{getInfoText(listItem.info)}</p>
                            <ul className={isEng ? 'eng' : ''}>
                              {listItem.list.map((prop, propIndex) =>
                                <li key={propIndex}>
                                  <strong className='cate'>{prop.label}</strong>
                                  <span className='info'>{formatData(getListText(prop.value))}</span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </SwiperSlide>
                    )}
                  </Swiper>
                  <p className='m_info'>※ 제품 클릭 시 상세 팝업 확인이 가능합니다.</p>
                </>
              }
            </div>
          </div>
        </section>

        {/* 모달 팝업 */}
        {isMobile && selectItem && (
          <PadModal item={selectItem} onClose={() => setSelectItem(null)}/>
        )}
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  & .solution_sec {
    position: relative;
    padding: 140px 0 200px;

    & .solution_con {
      margin-top: 80px;

      > .pad_list {
        .list_item {
          & + .list_item {
            margin-top: 30px;
          }

          & .item_in {
            padding: 35px 30px;
            display: flex;
            align-items: center;
            gap: 0 30px;
            border: 1px solid var(--color-grey-20);

            & .item_img {
              max-width: 400px;
            }

            & .item_desc {
              flex: 1;

              & > strong {
                font-weight: 700;
                font-size: var(--font-card-tit);
                color: var(--color-grey-100);
              }

              & > span {
                margin-top: 10px;
                display: block;
                font-weight: 500;
                font-size: var(--font-nav-sub-tit);
                color: var(--color-grey-60);
              }

              & p {
                margin-top: 30px;
                display: block;
                font-weight: 400;
                font-size: var(--font-nav-sub-tit);
                line-height: 1.5;
                color: var(--color-grey-80);
                white-space: pre-line;
              }

              & ul {
                margin-top: 60px;
                display: flex;
                flex-wrap: wrap;
                border-top: 1px solid var(--color-grey-60);
                border-bottom: 1px solid var(--color-grey-60);

                & li {
                  width: 50%;
                  text-align: center;

                  & .cate {
                    padding: 15px 0;
                    display: block;
                    font-weight: 600;
                    font-size: var(--font-nav-sub-tit);
                    color: var(--color-grey-60);
                    letter-spacing: 0.03rem;
                  }

                  & .info {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top: 1px solid var(--color-grey-20);
                    min-height: 70px;
                    font-weight: 400;
                    font-size: var(--font-nav-sub-tit);
                    line-height: 1.2;
                    color: var(--color-grey-90);
                  }
                }

                & li:nth-child(n+3) {
                  border-top: 1px solid var(--color-grey-60);
                }
              }
            }
          }
        }
      }

      .m_info {
        margin-top: 10px;
        display: block;
        font-weight: 700;
        font-size: var(--font-history-txt);
        color: var(--primary-color);
      }

      & .catalogue_btn {
        margin-top: 45px;
        display: flex;
        justify-content: flex-end;

        & a {
          cursor: pointer;
          position: relative;
          padding: 0 30px;
          display: flex;
          align-items: center;
          height: 50px;
          line-height: 50px;
          border: 1px solid var(--primary-color);
          border-radius: 100px;
          font-weight: 500;
          font-size: 18px;
          color: var(--primary-color);
          font-family: var(--font-family);
          transition: var(--transition);

          &::after {
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/solution/down_ic.svg') no-repeat;
            background-size: cover;
            transition: var(--transition);
          }

          &:hover {
            color: var(--color-white);
            background: var(--primary-color);

            &::after {
              filter: grayscale(1) brightness(5);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    & .solution_sec {
      position: relative;
      padding: 70px 0 90px;

      & .solution_con {
        margin-top: 60px;

        .pad_list {
          position: relative;

          .list_item {
            & + .list_item {
              margin-top: 0;
            }

            & .item_in {
              padding: 25px;
              display: block;
              border: 1px solid var(--color-grey-20);

              & .item_img {
                max-width: none;
                width: 100%;

                img {
                  width: 100%;
                }
              }

              & .item_desc {
                margin-top: 20px;
                width: 100%;

                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                & p {
                  margin-top: 15px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                & ul {
                  margin-top: 20px;
                  display: block;

                  & li {
                    width: 100%;
                    padding: 12px;
                    text-align: left;

                    & .cate {
                      padding: 0;
                      flex: 0.7;
                    }

                    & .info {
                      margin-top: 5px;
                      border: none;
                      flex: 1;
                      display: block;
                      height: auto;
                      text-align: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      min-height: auto;

                      br {
                        display: none;
                      }
                    }
                  }

                  li + li {
                    border-top: 1px solid var(--color-grey-60);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    & .solution_sec {
      position: relative;
      padding: 35px 0 45px;

      & .solution_con {
        margin-top: 30px;
      }
    }
  }
`;

export default SolutionPad;