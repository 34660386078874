import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';


export default function SEOMeta({ pageData }) {
  const { title, desc, keywords, image, url } = pageData;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='title' content={title}/>
      <meta name='description' content={desc}/>
      <meta name='keywords' content={keywords}/>
      <meta name='robots' content='index, follow'/>

      <meta property='og:type' content='website'/>
      <meta property='og:title' content={title}/>
      <meta property='og:image' content={image}/>
      <meta property='og:description' content={desc}/>
      <meta property='og:url' content={url}/>
      <meta property='og:image:width' content='1200'/>
      <meta property='og:image:height' content='630'/>

      <meta name='twitter:title' content={title}/>
      <meta name='twitter:description' content={desc}/>
      <meta name='twitter:image' content={image}/>
    </Helmet>
  );
}

SEOMeta.propTypes = {
  pageData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    keywords: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};
